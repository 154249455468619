import { useEffect, useContext, useRef } from 'react';
import BuzzContext from '../../contexts/buzz';
import DestinationContext from '../../contexts/destination';
import {
  attachClientImpressionHandler,
  attachClientInternalLinkHandler,
  attachClientExternalLinkHandler,
} from './client-event-tracking';

import { getElementData } from '@buzzfeed/bf-utils/lib/affiliate-link';
import { cleanLink } from './utils';

export function useUnitTracking(
  unitData,
  setEventLabel = false,
  external = false
) {
  const ref = useRef(null);
  const buzz = useContext(BuzzContext);
  const { destination } = useContext(DestinationContext);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return () => {};
    }

    const detachImpressionHandler = attachClientImpressionHandler(
      element,
      buzz,
      unitData
    );

    const detachLinkHandler = external
      ? attachClientExternalLinkHandler(element, buzz, unitData)
      : attachClientInternalLinkHandler(element, buzz, unitData);

    function googleEventListener(ev) {
      if (setEventLabel) {
        const linkElement = ev.target.closest('a');
        if (linkElement) {
          const data = getElementData(linkElement);
          unitData.eventLabel = data.href ? cleanLink(data.href) : undefined;
        }
      }
    }

    element.addEventListener('click', googleEventListener);

    return () => {
      detachImpressionHandler();
      detachLinkHandler();
      element.removeEventListener('click', googleEventListener);
    };
  }, [buzz, destination, unitData, setEventLabel, external]);

  return ref;
}
