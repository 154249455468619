import React, { useEffect, useState } from 'react';
import { Tooltip } from '@buzzfeed/react-components';

function TooltipWrapper({
  containerEl,
  contentEl,
  position = 'top',
  text,
  themes,
}) {
  const [shouldShow, setShouldShow] = useState(true);
  const containerRef = React.createRef();
  containerRef.current = containerEl;

  useEffect(() => {
    setTimeout(() => {
      setShouldShow(false);
      setTimeout(() => {
        if (contentEl) {
          contentEl.removeChild(containerEl);
        }
      }, 1000);
    }, 3000);
  }, [containerEl, contentEl]);

  return (
    <Tooltip
      container={containerRef}
      position={position}
      shouldShow={shouldShow}
      themes={themes}
      text={text}
    />
  );
}

export default TooltipWrapper;
