/**
 * Add Legacy BFA events for video triggered by subbuzzes
 */
import { trackClientVideoView } from '../../client-event-tracking';

/**
 *
 * @param {Object} tracker - the tracking object defined in useLegacyTracking/index.js
 * @param {Object} buzz - full buzz object
 */
export default function addSubbuzzVideoEvents(tracker, { buzz }) {
  const subbuzzVideoEvents = {
    trackVideoEvents(data) {
      trackClientVideoView(buzz, {
        item_name: data.item_name || data.youtube_id || data.video_id,
        item_type: 'video',
        subunit_name: 0,
        subunit_type: 'subbuzz',
        unit_name: buzz.id,
        unit_type: 'buzz',
      });
    },
  };

  // add functions to root tracker, to be called as tracker.functionName
  Object.keys(subbuzzVideoEvents).forEach(
    k => (tracker[k] = subbuzzVideoEvents[k])
  );
}
