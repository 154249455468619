import {
  trackFacebookQuizAnswer,
  trackFacebookQuizComplete,
} from '../../facebook/client';
import {
  trackClientContentAction,
  trackClientInternalLink,
  trackClientQuizAnswer,
  trackClientQuizComplete,
} from '../../client-event-tracking';

/**
 * Add Legacy BFA events for quiz actions triggered by subbuzzes and BFP quiz formats
 */

const cleanQuizId = id => {
  // some quizzes send negative numbers as IDs due to string parse errors
  // normalize to positive integers
  let returnId = id;

  try {
    const cleanId = Math.abs(parseInt(id, 10));
    if (!isNaN(cleanId)) {
      returnId = cleanId;
    }
  } catch (e) {
    // log the error and continue
    console.error(e);
  }

  return returnId;
};

/**
 *
 * @param {Object} tracker - the tracking object defined in useLegacyTracking/index.js
 * @param {Object} buzz - full buzz object
 * @param {Function} createShareTrackingFn - Function that returns a tracking event function for a given event context
 */
export default function addQuizEvents(
  tracker,
  { buzz, createShareTrackingFn }
) {
  const quizEvents = {
    // SRA
    trackSraQuizAnswer({ data }) {
      trackClientQuizAnswer(buzz, {
        action_type: 'quiz_answer',
        action_value: `${data.answerId}`,
        item_name: `${data.answerId}`,
        item_type: 'quiz_answer',
        subunit_name: `${data.questionId}`,
        subunit_type: 'quiz_question',
        unit_name: buzz.id,
        unit_type: 'buzz_body',
      });
      trackFacebookQuizAnswer(buzz.destination_name, buzz, {
        buzz_id: buzz.id,
        quiz_id: data.quizId,
        quiz_question_id: data.questionId,
        quiz_answer_id: data.answerId,
      });
    },

    trackSraQuizComplete({ data }) {
      trackClientQuizComplete(buzz, {
        action_type: 'quiz_result',
        action_value: `${data.itemId || data.resultId}`,
      });
      trackFacebookQuizComplete(buzz.destination_name, buzz, {
        buzz_id: buzz.id,
        quiz_id: data.quizId,
        quiz_result: data.itemId || `${data.current} out of ${data.total}`,
      });
    },

    trackSraQuizRetake({ data }) {
      trackClientContentAction(buzz, {
        action_type: 'quiz_retake',
        action_value: buzz.id,
        item_name: data.itemId || data.resultId || data.quizId,
        item_type: 'quiz_result',
        subunit_name: data.questionId || data.quizId || '',
        subunit_type: 'quiz_question',
        unit_name: buzz.id,
        unit_type: 'buzz_body',
      });
    },

    trackSraQuizShare(eventInfo) {
      let data = eventInfo;
      if (eventInfo.data) {
        data = {
          ...eventInfo,
          ...eventInfo.data,
        };
      }
      const trackingFn = createShareTrackingFn({
        platform: data.d,
        position: 'quiz_results',
        item_type: 'quiz_result',
        item_name: data.itemId || data.resultId || data.quizId,
      });
      trackingFn();
    },

    // BFP
    trackBfpQuizAnswer(eventInfo) {
      tracker.trackSraQuizAnswer({
        data: {
          ...eventInfo.data,
          questionId: cleanQuizId(eventInfo.data.questionId),
          answerId: cleanQuizId(eventInfo.data.answerId),
        },
      });
    },

    trackBfpQuizComplete({ data }) {
      tracker.trackSraQuizComplete({
        data: {
          ...data,
          itemId: cleanQuizId(data.resultId),
        },
      });
    },

    trackBfpQuizResultSignup() {
      trackClientInternalLink(buzz, {
        item_name: 'sign_up',
        item_type: 'button',
        subunit_name: 'quiz_result',
        subunit_type: 'component',
        target_content_id: 'sign_in',
        target_content_type: 'auth',
        unit_name: buzz.id,
        unit_type: 'buzz_bottom',
      });
    },

    trackBfpQuizPin({ data }) {
      trackClientContentAction(buzz, {
        action_type: 'save',
        action_value: data.resultId || '',
        item_name: 'pin_to_my_profile',
        item_type: 'button',
        subunit_name: 'quiz_result',
        subunit_type: 'component',
        unit_name: buzz.id,
        unit_type: 'buzz_bottom',
      });
    },

    trackBfpQuizPinReplace({ data }) {
      trackClientContentAction(buzz, {
        action_type: 'save',
        action_value: data.resultId || '',
        item_name: 'pin_new_result',
        item_type: 'button',
        subunit_name: 'quiz_result',
        subunit_type: 'component',
        unit_name: buzz.id,
        unit_type: 'buzz_bottom',
      });
    },

    trackBfpQuizPinModal({ data }) {
      trackClientInternalLink(buzz, {
        item_name: 'visit_profile',
        item_type: 'button',
        subunit_name: 'quiz_result',
        subunit_type: 'component',
        target_content_id: data.uuid || '',
        target_content_type: 'user',
        unit_name: buzz.id,
        unit_type: 'buzz_bottom',
      });
    },

    trackBfpQuizLoginPrompt() {
      trackClientInternalLink(buzz, {
        item_name: 'log_in',
        item_type: 'text',
        subunit_name: 'quiz_result',
        subunit_type: 'component',
        target_content_id: 'sign_in',
        target_content_type: 'auth',
        unit_name: buzz.id,
        unit_type: 'buzz_bottom',
      });
    },
  };

  // add functions to root tracker, to be called as tracker.functionName
  Object.keys(quizEvents).forEach(k => (tracker[k] = quizEvents[k]));

  // update tracking path mapping for the generic `track` function
  tracker.trackingPathsMap = {
    ...tracker.trackingPathsMap,
    'quiz/answer': tracker.trackSraQuizAnswer,
    'quiz/complete': tracker.trackSraQuizComplete,
    'quiz/retake': tracker.trackSraQuizRetake,
    'quiz/share': tracker.trackSraQuizShare,
  };
}
