import React, { useContext } from 'react';
import { SiteHeader as Header } from '@buzzfeed/react-components';
import BuzzContext from '../../contexts/buzz';

function SiteHeader(header) {
  const { destination_name } = useContext(BuzzContext);
  const headerProps = { ...header };
  if (destination_name === 'buzzfeed_news') {
    // Override sticky header class, so registry is not set for news bpages
    headerProps.stickyRegistryClass = '';
  }

  return (
    <Header {...headerProps} />
  );
}

export default SiteHeader;
