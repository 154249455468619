import { useContext } from 'react';
import { useIntersection } from '@buzzfeed/react-components';
import BuzzContext from '../../contexts/buzz';

/**
 * @typedef {Object} useLazyLoadResult
 * @property {Boolean} canLoad - whether the component can start delayed computations
 * @property {Function} setObservable - should be called with the component's root DOM node
 */

/**
 * Hook for delaying computations based on a component's position relative to viewport
 * @param {Number} [options.preloadDistance=2] - how close to viewport the component shoud be to be considered "ready";
 *  `{preloadDistance: N}` means distance equal or less than N times viewport height
 * @param {String} [options.rootMargin] - raw `rootMargin` param for an intersection observer
 *  (takes precedence over `preloadDistance`)
 * @returns {useLazyLoadResult}
 */
export default function useLazyLoad({ preloadDistance = 2, rootMargin } = {}) {
  const { id: pageId } = useContext(BuzzContext);
  if (rootMargin === undefined) {
    const pageHeight =
      typeof document !== 'undefined'
        ? document.documentElement.clientHeight
        : 0;
    rootMargin = `${pageHeight * preloadDistance}px 0px`;
  }
  const { isIntersecting, setObservable } = useIntersection({
    rootMargin,
    once: true,
    pageId,
  });

  return { canLoad: isIntersecting, setObservable };
}
