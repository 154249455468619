import { OutbrainTracker } from '@buzzfeed/third-party-analytics';
import { CLUSTER, outbrain_ids } from '../../../constants';

let trackingInstance;

const ob = () => {
  if (!trackingInstance) {
    trackingInstance = new OutbrainTracker({
      cluster: CLUSTER,
      ids: outbrain_ids,
    });
  }
  return trackingInstance;
};

export async function trackOutbrainPageView() {
  const tracker = ob();
  tracker.trackPageView();
}

export async function trackOutbrainAffiliateClick() {
  const tracker = ob();
  tracker.trackAffiliateClick();
}
