import SocialWidgets from '../../../SocialWidgets';
import { SidebarBrandedRecircList } from '../../../RecircList/variants';
import AdUnit from '../../../Ads/AdUnit';

function SidebarBranded({ buzz, containerRef, pixiedust }) {
  const userByline =
    buzz.bylines.find(byline => byline.username === buzz.username) || {};

  const widgetCount = ['twitter_page_url', 'facebook_page_url'].reduce(
    (count, widget) => {
      if (userByline[widget]) {
        count++;
      }
      return count;
    },
    0
  );

  return (
    <>
      <AdUnit type="bigstory" pixiedust={pixiedust} />

      <SocialWidgets
        twitterPageUrl={userByline.twitter_page_url}
        facebookPageUrl={userByline.facebook_page_url}
        displayName={userByline.display_name}
      />

      <SidebarBrandedRecircList
        containerRef={containerRef}
        displayName={userByline.display_name}
        widgetCount={widgetCount}
      />
    </>
  );
}

export default SidebarBranded;
