import styles from './index.module.scss';
import DestinationContext from '../../contexts/destination';
import { destinations } from '../../constants';
import * as Sentry from '@sentry/nextjs';

export const DestinationWrapper = ({ destination, children }) => {
  // Expose the destination on all sentry events
  Sentry.setTag('destination', destination);

  const destinationConfig = {
    /**
     * destination as in the CMS/Buzz API; This may be buzzfeed_news for articles originally
     * posted there. This is still needed and used for some of rendering on the page
     **/
    destination,
    /**
     * The host the article will render on, regardless of destination set in CMS/Buzz API.
     * This can be used for CET and anything where buzzfeed.com should be used instead of BFN
     **/
    destinationHost: ['buzzfeed_news', 'buzzfeed'].includes(destination) ? 'buzzfeed' : destination,
    ...destinations[destination],
  };
  return (
    <DestinationContext.Provider value={destinationConfig}>
      <div className={styles[destination]}>{children}</div>
    </DestinationContext.Provider>
  );
};
