import React, { useContext, useEffect, useRef, useState } from 'react';
import { debugMode } from '@buzzfeed/adlib/dist/module/core/debug-mode/index';
import { useLockedEffect } from '../../../../hooks/useLockedEffect';
import CommentsContext from '../../../../contexts/comments';
import SidebarAdUnit from '../../../Ads/components/SidebarAd';
import SidebarItem from '../SidebarItem';
const SLOT_REFRESH_FREQUENCY = 32000;


/**
 * How pagination works:
 *
 * 1. `SidebarPaginated` updates the sidebar page
 * 2. `SidebarDensity` renders _all children_, each wrapped in a `SidebarItem` component.
 * The child whose index in the array of children corresponds to the current sidebar page is rendered
 * in the "active" mode, others in the "inactive" mode.
 * `SidebarItem` returns null for all inactive children
 * 3. `SidebarAd` (the active child) acquires an `active-item` lock
 * 4. `SidebarDensity` acquires a `timer` lock
 * 5. `SidebarDensity` acquires a `preload-item` lock
 * (even though it hasn't started preloading the next item yet)
 * 6. `AdUnit` releases the `active-item` lock when adlib signals that the ad is "viewed"
 * 7. `SidebarDensity` releases the `timer` lock after 15 seconds.
 * This may happen before or after the `active-item` lock is released
 * 8. `SidebarDensity` re-renders the children: the child next to the active one is rendered
 * in the "preload" mode this time, other children are unchanged.
 * `SidebarItem` hides the preloading child with `display: none`
 * 9. `SidebarAd` (the preloading child) releases the `preload-item` lock
 * as soon as an ad has been received from GAM
 *
 * There may be three additional locks acquired at some point:
 * `visibility` lock is acquired if less than 50% of the sidebar container is in view;
 * `page-focus` lock is acquired if the browser window or tab is not focused
 * `debug` lock is acquired if there's a URL param for debugging
 * 10. As soon as all the locks described above have been released,
 * a callback prop that increments the sidebar page is called
 * 11. See step 1
 */

function SidebarDensity({
  sidebarPage,
  children,
  onNextPageReady,
  isIntersecting,
  adManager,
}) {
  const { isCommentsPanelOpen } = useContext(CommentsContext);
  const [acquireLock, releaseLock] = useLockedEffect(onNextPageReady);
  const childrenCount = React.Children.count(children);
  const [currentItem, setCurrentItem] = useState(null);
  const [nextItem, setNextItem] = useState(null);
  const [isTimerLocked, setTimerLocked] = useState(true);
  const [isCurrentItemLocked, setCurrentItemLocked] = useState(true);
  const [preloadNext, setPreloadNext] = useState(false);
  const [isFocused, setIsFocused] = useState(true);
  const prevSidebarPage = useRef(null);
  const timer = useRef(null);


  // do not paginate if the sidebar container is less than 50% visible
  useEffect(() => {
    if (isIntersecting) {
      releaseLock('sidebar:visibility');
      debugMode('info', 'lifecycle', 'Sidebar Refresh: Release visibility lock');
    } else {
      acquireLock('sidebar:visibility');
      debugMode('info', 'lifecycle', 'Sidebar Refresh: Acquire visibility lock');
    }
  }, [isIntersecting, acquireLock, releaseLock]);

  // do not paginate if the condensed comments sidebar is visible
  useEffect(() => {
    if (isCommentsPanelOpen) {
      acquireLock('sidebar:condensed-comments');
    } else {
      releaseLock('sidebar:condensed-comments');
    }
  }, [isCommentsPanelOpen, acquireLock, releaseLock]);

  useEffect(() => {
    debugMode('info', 'lifecycle', 'Sidebar Refresh: SLOT_REFRESH_FREQUENCY:', SLOT_REFRESH_FREQUENCY);
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const handleVisibility = () =>
      setIsFocused(document.visibilityState === 'visible');

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    window.addEventListener('visibilitychange', handleVisibility);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('visibilitychange', handleVisibility);
    };
  }, []);

  // stop pagination if the browser tab and window are not focused
  useEffect(() => {
    if (isFocused) {
      releaseLock('sidebar:page-focus');
      debugMode('info', 'lifecycle', 'Sidebar Refresh: Release page-focus lock');
    } else {
      acquireLock('sidebar:page-focus');
      debugMode('info', 'lifecycle', 'Sidebar Refresh: Acquire page-focus lock');
    }
  }, [isFocused, acquireLock, releaseLock]);

  // stop pagination after the number of pages specified in the debug URL param
  useEffect(() => {
    const stopAfter = new URL(location).searchParams.get('sidebar-debug');
    if (stopAfter !== null && sidebarPage >= stopAfter - 1) {
      acquireLock('sidebar:debug');
    } else {
      releaseLock('sidebar:debug');
    }
  }, [sidebarPage, acquireLock, releaseLock]);

  // The effect handles the `timer` lock (one of the four locks used by the component)
  useEffect(() => {
    // do not reset the timer unless render is caused by a changed sidebar page
    if (sidebarPage === prevSidebarPage.current) {
      return;
    }
    prevSidebarPage.current = sidebarPage;
    setPreloadNext(false);
    // lock the next item early (without actually preloading it yet)
    // so sidebar page doesn't change prematurely when both timer and current item locks are released
    acquireLock('sidebar:preload-item');
    clearTimeout(timer.current);
    acquireLock('sidebar:timer');
    setTimerLocked(true);
    timer.current = setTimeout(() => {
      debugMode('info', 'lifecycle', `Sidebar Refresh: Starting timer... (page: ${sidebarPage})`);
      timer.current = null;
      releaseLock('sidebar:timer');
      setTimerLocked(false);
    }, SLOT_REFRESH_FREQUENCY);
  }, [sidebarPage, acquireLock, releaseLock]);

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  // The effect starts preloading the next item
  // when the current item's lock has been released and the timer has expired.
  useEffect(() => {
    if (!isTimerLocked && !isCurrentItemLocked) {
      setPreloadNext(true);
    }
  }, [nextItem, isTimerLocked, isCurrentItemLocked]);

  // The effect manages which sidebar item is currently displayed/active
  // and which, if any, should prepare itself for becoming active next
  useEffect(() => {
    setCurrentItem({
      idx: sidebarPage % childrenCount,
      props: {
        mode: 'active',
        sidebarPage,
        acquireLock: () => {
          acquireLock('sidebar:active-item');
          setCurrentItemLocked(true);
        },
        releaseLock: () => {
          releaseLock('sidebar:active-item');
          setCurrentItemLocked(false);
        },
      },
    });
    const nextPage = sidebarPage + 1;
    setNextItem({
      idx: nextPage % childrenCount,
      props: {
        mode: 'preload',
        sidebarPage: nextPage,
        acquireLock: () => acquireLock('sidebar:preload-item'),
        releaseLock: () => releaseLock('sidebar:preload-item'),
      },
    });
  }, [sidebarPage, childrenCount, acquireLock, releaseLock]);

  // The effect auto-updates the sidebar ad manager's density pattern
  // based on type and order of items in the array of children
  useEffect(
    () => {
      if (adManager) {
        // e.g. if there's 2 ad pages followed by 1 recirc page, the pattern will be [true, true, false]
        adManager.pattern = React.Children.map(
          children,
          child => child.type === SidebarAdUnit
        );
      }
    },
    // the `children` array is different on every render,
    // so there's no performance benefit in specifying a dependencies array, but still doing it for clarity
    [children, adManager]
  );

  return (
    currentItem &&
    nextItem &&
    React.Children.map(children, (child, idx) => {
      const isActive = idx === currentItem.idx;
      const isPreload = preloadNext && idx === nextItem.idx;
      const childProps = isActive
        ? currentItem.props
        : isPreload
        ? nextItem.props
        : { mode: 'inactive' };
      return (
        <SidebarItem sidebarPage={sidebarPage} {...childProps}>
          {child}
        </SidebarItem>
      );
    })
  );
}

export default SidebarDensity;
