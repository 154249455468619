import { programmaticSizes } from '@buzzfeed/adlib/dist/module/bindings/react';
import sizeUtils from '@buzzfeed/adlib/dist/module/services/size/standalone';
import {
  components as adlibComponents,
  templates as adlibTemplates,
} from './adlib';

export function isInline(adConfig) {
  const { adPos } = adConfig;
  return /^promo-inline/.test(adPos);
}

export function isWideInline(adConfig) {
  const { adPos } = adConfig;
  return /^promo\d*-wide/.test(adPos);
}

// eslint-disable-next-line consistent-return
export function getTemplate(adConfig) {
  const { adPos, size } = adConfig;
  if (/^awareness/.test(adPos)) {
    return adlibTemplates.AdAwareness;
  } else if (/^(sidebar|bigstory)/.test(adPos)) {
    return adlibTemplates.AdBigstory;
  } else if (isInline(adConfig)) {
    return adlibTemplates.AdPromoInline;
  } else if (isWideInline(adConfig) || adPos === 'aiquizzes') {
    return adlibTemplates.AdEx;
  } else if (adPos === 'story-bpage') {
    return adConfig.isMobile
      ? adlibTemplates.AdGridStory
      : adlibTemplates.AdBpageStory;
  } else if (/^promo-bottom/.test(adPos)) {
    return adlibTemplates.AdExGrid;
  } else if (/^subbuzz/.test(adPos)) {
    return adlibTemplates.AdSubbuzz;
  } else if (/pixel$/.test(adPos)) {
    return adlibTemplates.AdAffiliatePixel;
  } else if (/tb$/.test(adPos)) {
    return adlibTemplates.AdToolbar;
  }
}

export function getComponent(adConfig) {
  const { adPos } = adConfig;
  if (/^awareness/.test(adPos)) {
    return adlibComponents.AdAwareness;
  } else if (/^(sidebar|bigstory)/.test(adPos)) {
    return adlibComponents.AdBigstory;
  } else if (isInline(adConfig)) {
    return adlibComponents.AdPromoInline;
  } else if (adPos === 'story-bpage') {
    return adConfig.isMobile
      ? adlibComponents.AdGridStory
      : adlibComponents.AdBpageStory;
  } else if (/^promo-bottom/.test(adPos)) {
    return adlibComponents.AdExGrid;
  } else if (/^subbuzz/.test(adPos)) {
    return adlibComponents.AdSubbuzz;
  } else if (/pixel$/.test(adPos)) {
    return adlibComponents.AdAffiliatePixel;
  } else if (/tb/.test(adPos)) {
    return adlibComponents.AdToolbar;
  }
  return adlibComponents.AdEx;
}

export function getMaxSizes(adConfig) {
  const sizes = sizeUtils.getProgrammatic(programmaticSizes, adConfig.size);
  const maxHeight = Math.max(0, ...sizes.map(([, h]) => h));
  const maxWidth = Math.max(0, ...sizes.map(([w]) => w));
  return [maxWidth, maxHeight];
}

export function getWireframeOptions(adConfig) {
  if (!(isInline(adConfig) || isWideInline(adConfig))) {
    return {};
  }
  const maxSizes = getMaxSizes(adConfig);
  return {
    programmaticWireframes: true,
    wireframeWidth: maxSizes[0],
    wireframeHeight: maxSizes[1],
    wireframeClasses: defaultClasses =>
      `${defaultClasses} ad-wireframe-wrapper--inline`,
  };
}
