import { destinations, CLUSTER } from '../../../constants';
import { FacebookTracker } from '@buzzfeed/third-party-analytics';

let trackingInstances = {};

const fb = (destination, buzz) => {
  /*
    initialize only when an instace does not exist (an inital request)
    or destination has been changed (switched between bfo and bfn)
  */
  if (buzz?.hasVideoSubbuzz) {
    console.info('Disabled the Facebook Pixel on posts that include a video');
    return {
      trackEvent: () => {}
    };
  }

  if (!buzz?.isShopping) {
    console.info('Disabled the Facebook Pixel on non-shopping posts');
    return {
      trackEvent: () => {}
    };
  }

  if (!trackingInstances[destination]) {
    const { facebook_tracking_id } = destinations[destination];
    trackingInstances[destination] = new FacebookTracker({
      trackingId: facebook_tracking_id,
      cluster: CLUSTER,
    });
  }
  return trackingInstances[destination];
};

// Page view
export async function trackFacebookPageView(destination, buzz, data) {
  const tracker = await fb(destination, buzz);
  tracker.trackEvent({ eventType: 'PageView', data });
}

// Links
export async function trackFacebookAffiliateClick(destination, buzz, data) {
  const tracker = await fb(destination, buzz);
  tracker.trackEvent({ eventType: 'AddToCart', data });
  tracker.trackEvent({ eventType: 'Purchase', data });
}

// Newsletter signup
export async function trackFacebookNewsletterSignup(destination, buzz, data) {
  const tracker = await fb(destination, buzz);
  tracker.trackEvent({
    eventType: 'newsletterSignup',
    data,
    options: { custom: true },
  });
}

// Sharing
export async function trackFacebookShareClick(destination, buzz, data) {
  const tracker = await fb(destination, buzz);
  tracker.trackEvent({
    eventType: 'Share',
    data,
    options: { custom: true },
  });
}

export async function trackFacebookLead(destination, buzz, data) {
  const tracker = await fb(destination, buzz);
  tracker.trackEvent({
    eventType: 'Lead',
    data,
  });
}

// Quizzes
export async function trackFacebookQuizComplete(destination, buzz, data) {
  const tracker = await fb(destination, buzz);
  tracker.trackEvent({
    eventType: 'CompletedQuiz',
    data,
    options: { custom: true },
  });
}

export async function trackFacebookQuizAnswer(destination, buzz, data) {
  const tracker = await fb(destination, buzz);
  tracker.trackEvent({
    eventType: 'QuizAnswer',
    data,
    options: { custom: true },
  });
}
