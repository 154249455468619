import dynamicImport from '../../../utils/dynamic-import';
import RelatedRecircList from './RelatedRecircList/lazy';
import BottomRecircList from './BottomRecircList/lazy';

const SidebarBrandedRecircList = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "recirculation-sidebar-branded" */ './SidebarBrandedRecircList'
    ),
  { ssr: false }
);
const SidebarRecircList = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "recirculation-sidebar" */ './SidebarRecircList'
    ),
  { ssr: false }
);

export {
  BottomRecircList,
  RelatedRecircList,
  SidebarBrandedRecircList,
  SidebarRecircList,
};
