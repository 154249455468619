import { isServer } from './isServer';
import { useEffect } from 'react';

export class BuzzTracker {
  constructor({ max_size = 20, storage_key = 'buzz_history' } = {}) {
    this.storage = (!isServer() && localStorage) || {};
    this.max_size = max_size;
    this.storage_key = storage_key;
    this.ids = this.read();
  }

  getIds() {
    return this.ids;
  }

  add(newId) {
    if (!newId) {
      return;
    }
    const newState = this.ids.filter(id => id !== newId);
    newState.push(newId);
    this.ids = newState.slice(-this.max_size);
    this.write();
  }

  read() {
    const entry = this.storage[this.storage_key];
    if (!entry) {
      return [];
    }
    let parsed = [];
    try {
      parsed = JSON.parse(entry);
    } catch {
      // intentionally continue
    }
    return parsed;
  }

  write() {
    this.storage[this.storage_key] = JSON.stringify(this.ids);
  }
}

export const buzzTracker = new BuzzTracker();

export function useLocalBuzzTracking(buzzId) {
  useEffect(() => {
    buzzTracker.add(buzzId);
  }, [buzzId]);
}
