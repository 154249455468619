import React, { useContext } from 'react';
import BuzzContext from '../../contexts/buzz';
import dynamicImport from '../../utils/dynamic-import';
import LazyLoad from '../LazyLoad';

const NewsletterSignup = dynamicImport(
  () => import(/* webpackChunkName: "newsletter-signup" */ './index.jsx'),
  { ssr: false }
);

export default function NewsletterSignupLazy(props) {
  const buzz = useContext(BuzzContext);
  if (
    buzz.tags.includes('--no-newsletter-signup') ||
    buzz.bfpFormats.includes('newsletter_signup') ||
    buzz.flags.nsfw ||
    buzz.isAd
  ) {
    return null;
  }

  return (
    <LazyLoad>
      <NewsletterSignup {...props} />
    </LazyLoad>
  );
}
