import { useEffect, useRef, useState, useCallback } from 'react';

/**
 * State and render hook:
 * Allows consumer component to block the callback from being executed until all locks are released.
 * Once all locks are released component's state updates and the callback is executed during render effect.
 * Locks are acquired and released by their unique name.
 * At least one lock has to be acquired and then released to execute the callback.
 * @param {Function} callback - will be called after next render when all locks are released
 */
export function useLockedEffect(callback) {
  const allLocks = useRef(new Set());
  const [released, setReleased] = useState(false);
  const acquireLock = useCallback(name => allLocks.current.add(name), []);
  const releaseLock = useCallback(name => {
    if (!allLocks.current.has(name)) {
      console.warn(`Attempted to release a free '${name}' lock`);
      return;
    }
    allLocks.current.delete(name);
    if (allLocks.current.size === 0) {
      setReleased(true);
    }
  }, []);

  // Effect to execute the provided callback function when all locks are released
  useEffect(() => {
    if (released) {
      callback();
      setReleased(false);
      return;
    }
  }, [callback, released]);

  return [acquireLock, releaseLock];
}
