import {
  getKeywords,
  getRetailers as getRetailersUtils,
} from '@buzzfeed/bf-utils/lib/keywords';
import { PermutiveTracker } from '@buzzfeed/third-party-analytics';
import { CLUSTER, destinations } from '../../../constants';

let trackingInstances = {};

const permutiveCredentials = ({ classification, destination_name }) => {
  const { edition } = classification;
  const permutiveCreds = destinations[destination_name].permutive_creds;
  const { api_key, project_id } =
    permutiveCreds[edition] || permutiveCreds['default'];

  return {
    projectId: project_id,
    apiKey: api_key,
  };
};

const permutive = buzz => {
  if (!buzz || !buzz.destination_name || !buzz.classification) {
    throw new Error(
      'Could not initialize Permutive because no buzz data was passed to establish the edition for a new instance.'
    );
  }

  const credentials = permutiveCredentials(buzz);
  const { destination_name, classification } = buzz;
  const { edition } = classification;
  if (!trackingInstances[`${destination_name}_${edition}`]) {
    trackingInstances[`${destination_name}_${edition}`] = new PermutiveTracker({
      cluster: CLUSTER,
      ...credentials,
    });
    trackingInstances[`${destination_name}_${edition}`].identify();
  }
  return trackingInstances[`${destination_name}_${edition}`];
};

const getRetailers = () => {
  const result = getRetailersUtils();
  document.querySelectorAll(`a[data-vars-retailers]`).forEach(link => {
    const retailers = link.getAttribute('data-vars-retailers').split(',');
    for (let retailer of retailers) {
      if (!result.includes(retailer)) {
        result.push(retailer);
      }
    }
  });
  return result.filter(Boolean);
};

const getWatsonTags = (buzz) => {
  return Object.values(buzz?.laser_tags?.watson || []).reduce((res, values) => {
    return res.concat(values.map(item => item.tag_display_name));
  }, []);
};

const getBFContentDescriptionTags = (buzz) => {
  return Object.values(buzz?.laser_tags?.bf_content_description || []).reduce((res, values) => {
    return res.concat(values.map(item => item.tag_display_name));
  }, []);
};

export async function trackPermutivePageView(buzz) {
  const tracker = permutive(buzz);
  const eventData = {
    page: {
      meta: {
        bf_buzzid: buzz.id,
        bf_userid: buzz.bylines[0].id,
        type: buzz.is_quiz ? 'quiz' : buzz.format.type,
        section: buzz.category,
        publisher: 'buzzfeed',
        title: buzz.title,
        description: buzz.description,
        author: buzz.bylines[0].username,
        tags: buzz.tags,
        platform: 'web',
        edition: (buzz.country_code || '').split('-')[1] || '',
        categories: buzz.sections,
        badges: buzz.badges ? buzz.badges.map(badge => badge.name) : undefined,
        keywords: getKeywords(),
        retailers: getRetailers(),
        watson_tags: getWatsonTags(buzz),
        bf_content_description: getBFContentDescriptionTags(buzz),
      },
    },
  };
  tracker.trackPageView(eventData);
}

export async function trackPermutiveAffiliateClick(data, buzz) {
  const tracker = permutive(buzz);
  tracker.trackAffiliateClick(data);
}
