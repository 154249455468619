import React, { useContext } from 'react';
import dynamicImport from '../../../../utils/dynamic-import';
import BuzzContext from '../../../../contexts/buzz';
import LazyLoad from '../../../LazyLoad';
import { isEligible } from './utils';

const RelatedRecircList = dynamicImport(
  () => import(/* webpackChunkName: "recirculation-related" */ './index'),
  { ssr: false }
);

export default function RelatedRecircListLazy(props) {
  const buzz = useContext(BuzzContext);

  if (!isEligible(buzz)) {
    return null;
  }

  return (
    <LazyLoad>
      <RelatedRecircList {...props} />
    </LazyLoad>
  );
}
