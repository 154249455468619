import { useCallback, useEffect, useState } from 'react';
import AdUnit from '../../AdUnit';

/**
 * This is the sidebar Ad unit
 * It signals when it's ready to be removed from the page
 * and has access to sidebar's context via props passed from the SidebarDensity component.
 */
function SidebarAdUnit({
  adManager,
  sidebarPage,
  mode,
  acquireLock,
  releaseLock,
  pixiedust,
}) {
  const [adConfig, setAdConfig] = useState(null);
  const [isLocked, setIsLocked] = useState(false);

  const releaseAdLock = useCallback(
    reason => {
      setIsLocked(false);
      releaseLock(reason);
    },
    [releaseLock]
  );

  useEffect(() => {
    acquireLock();
    setIsLocked(true);
  }, [acquireLock]);

  useEffect(() => {
    if (!adManager) {
      setAdConfig(null);
      return;
    }

    const ad = adManager.getAdForPlacement(sidebarPage);
    if (ad) {
      if (!ad.slot.targeting) {
        ad.slot.targeting = {};
      }
      ad.slot.targeting.sidebarPage = sidebarPage + 1;
    }
    setAdConfig(ad);
  }, [adManager, sidebarPage]);

  if (!adManager || !adConfig) {
    return null;
  }

  // do not let `releaseLock` to be called earlier than `acquireLock`
  // (since components are rendered from bottom to top
  // and `AdUnit` may be ready to call `releaseLock` immediately on update in some cases)
  return (
    <AdUnit
      config={adConfig.slot}
      onAdRender={isLocked && mode === 'preload' ? releaseAdLock : null}
      onAdViewed={isLocked && mode === 'active' ? releaseAdLock : null}
      pixiedust={pixiedust}
    />
  );
}

export default SidebarAdUnit;
