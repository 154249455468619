export function isEligible(buzz) {
  return ![
    buzz.isAd,
    buzz.isShopping,
    buzz.bfpFormats.includes('related_links'),
    buzz.format.page_type === 'long',
    buzz.tags.includes('coronavirus') || buzz.tags.includes('Coronavirus'),
    !buzz.isEnglish,
    !buzz.isModerated,
    buzz.shouldHideRecircSection
  ].some(x => x);
}
