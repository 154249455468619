import React from 'react';
import ReactDOM from 'react-dom';
import striptags from 'striptags';

import { getReferrer } from '@buzzfeed/bf-utils/lib/referrer';
import { i18n } from '../../i18n';
import { share as _share } from '@buzzfeed/bf-utils/lib/sharing';
import { objectToQueryString } from '@buzzfeed/bf-utils/lib/query-string';
import { isMobile as checkIfMobile } from '@buzzfeed/bf-utils/lib/device';
import { destinations } from '../../constants';
import TooltipWrapper from '../../components/TooltipWrapper';
import styles from '../../components/TooltipWrapper/tooltip-wrapper.module.scss';
import { decodeHtmlEntities } from '../../utils/decodeHtmlEntities';

export function getEligiblePlatforms(type, buzz, isMobile = false, position = '') {
  if (type === 'pagelevel' || type === 'pagelevelSticky') {
    if (buzz.destination_name === 'buzzfeed_news') {
      return ['twitter', 'facebook', 'copy'];
    }
    if (isMobile && position !== 'bottom_share_bar' ) {
      return buzz.classification.edition === 'Japan'
        ? ['facebook', 'twitter']
        : ['facebook', 'pinterest', 'copy'];
    } else {
      return buzz.category === 'Russia'
        ? ['facebook', 'vk', 'twitter', 'email', 'pinterest', 'copy']
        : ['copy', 'facebook', 'pinterest', 'twitter', 'email'];
    }
  } else if (type === 'pagelevelList') {
    return buzz.classification.edition === 'Japan'
      ? ['lineapp', 'sms', 'email', 'pinterest', 'snapchat', 'copy']
      : ['twitter', 'snapchat', 'whatsapp', 'sms', 'email', 'copy'];
  }
  return [];
}

export function share(platform, buzz, data) {
  const queryParams = {
    utm_source: 'dynamic',
    utm_campaign: `bfshare${platform}`,
  };
  const { canonical_url, shareData } = buzz;

  // quiz result specific fields
  const { itemId, quizId, subbuzzId, getCopyText } = data;
  let urlHash = '';
  if (itemId && quizId) {
    queryParams.quiz_result = `${quizId}_${itemId}`;
    queryParams.quote = striptags(data.text || data.title);
    urlHash = quizId;
    if (platform === 'twitter') {
      // rid = result/resource id.  Used to calculate picture pull from a url on twitter share once on the timeline
      queryParams.rid = itemId;
    }
  } else if (subbuzzId) {
    queryParams.sub = `0_${subbuzzId}`;
    urlHash = subbuzzId;
  }

  // set share url
  const url = `${canonical_url}${objectToQueryString(queryParams)}${
    urlHash ? `#${urlHash}` : ''
  }`;

  // set any needed platform fields, using data overrides if appropriate
  const platformOptions = {};
  if (platform === 'twitter') {
    platformOptions.title = striptags(
      data.text || data.title || shareData.twitter.title || buzz.title
    );
  } else if (platform === 'facebook') {
    platformOptions.fbAppId =
      destinations[buzz.destination_name].facebook_app_id;
  } else if (['lineapp', 'whatsapp'].includes(platform)) {
    platformOptions.title = striptags(data.title || buzz.title);
  } else if (platform === 'copy' && getCopyText) {
    platformOptions.getCopyText = getCopyText;
  }

  platformOptions.title = decodeHtmlEntities(platformOptions.title);

  const shareResult = _share(url, platform, platformOptions, !checkIfMobile());

  // for copy, add a tooltip based on share result
  if (platform === 'copy' && data.element) {
    const containerEl = document.createElement('div');
    containerEl.className = styles['tooltipContainer'];
    data.element.appendChild(containerEl);

    ReactDOM.render(
      <TooltipWrapper
        containerEl={containerEl}
        contentEl={data.element}
        text={
          shareResult ? i18n.t('link_copied') : i18n.t('unable_to_copy_link')
        }
        themes={shareResult ? ['dark', 'success'] : ['error']}
      />,
      containerEl
    );
  }
}
