import React from 'react';
import PropTypes from 'prop-types';

export default function SidebarItem({
  mode,
  children,
  sidebarPage,
  acquireLock,
  releaseLock,
}) {
  if (mode === 'inactive') {
    return null;
  }

  const child = React.cloneElement(children, {
    mode,
    sidebarPage,
    acquireLock,
    releaseLock,
  });
  return <div className={mode === 'preload' ? 'js-hidden' : ''}>{child}</div>;
}

SidebarItem.propTypes = {
  mode: PropTypes.oneOf(['active', 'inactive', 'preload']),
};
