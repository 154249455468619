import AdManager from '@buzzfeed/adlib/dist/module/core/base/manager';
import {
  config as adsConfig,
  sizes as adsSizes,
  getNumArray,
} from '@buzzfeed/adlib/dist/module/bindings/react';

function getSidebarConfig(buzz, pos) {
  if (buzz.isNews) {
    const sizeArrayWithoutPotraitAd = adsConfig[pos].size.filter(s => JSON.stringify(s) !== JSON.stringify([300, 600]));
    adsConfig[pos].size = sizeArrayWithoutPotraitAd;
  }

  return {
    ...adsConfig[pos],
    observeDimensions: true,
    loopVideo: false,
  };
}

export default function createSidebarAdManager(buzz) {
  // overriding the sizes to exclude the fluid size
  const size = buzz.isNews ? [
    adsSizes.NATIVE,
    adsSizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
    adsSizes.AMAZON_OUTSTREAM,
  ] : [
    adsSizes.NATIVE,
    adsSizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
    adsSizes.PROGRAMMATIC_VERTICAL,
    adsSizes.AMAZON_OUTSTREAM,
  ]
  const sidebar1 = {
    ...getSidebarConfig(buzz, 'sidebar1-bp'),
    size,
  };

  return new AdManager({
    config: {
      units: [sidebar1].concat(
        getNumArray(9, 2).map(i => getSidebarConfig(buzz, `sidebar${i}-bp`))
      ),
      unitsRepeated: [getSidebarConfig(buzz, 'sidebar-bp-infinite')],
      // will be automatically overridden by `SidebarDensity` based on what its children are
      pattern: [true],
    },
  });
}
