/**
 * Add Legacy BFA events for miscellaneous actions triggered by BFP formats
 */

import {
  attachClientExternalLinkHandler,
  attachClientImpressionHandler,
  attachClientInternalLinkHandler,
  attachClientContentAction,
  trackClientContentAction,
  trackClientInternalLink,
  trackClientExternalLink,
} from '../../client-event-tracking';

/**
 *
 * @param {Object} tracker - the tracking object defined in useLegacyTracking/index.js
 * @param {Object} buzz - buzz object
 */

export default function addBfpEvents(tracker, { buzz }) {
  const bindUnitTracking = ({ element, data, isWithImpression = true }) => {
    if (!element || !data) {
      return;
    }

    if (isWithImpression) {
      attachClientImpressionHandler(element, buzz, data);
    }

    if (data.target_content_url) {
      attachClientExternalLinkHandler(element, buzz, data);
    } else {
      attachClientInternalLinkHandler(element, buzz, data);
    }
  };

  const bfpEvents = {
    trackBfpClientContentAction({ data }) {
      trackClientContentAction(buzz, data);
    },

    trackBfpClientLinkClick({ data }) {
      if (data.target_content_url) {
        trackClientExternalLink(buzz, data);
      } else {
        trackClientInternalLink(buzz, data);
      }
    },

    bindBfpClientContentAction({ element, data }) {
      if (!element || !data) {
        return;
      }

      attachClientContentAction(element, buzz, data);
    },

    bindBfpProductTracking: bindUnitTracking,
    bindUnitTracking,

    trackMinibuzzInteraction() {
      /*
        Currently not tracked but defined as a no-op function to keep compatibility with BFP
        formats that call this function.
       */
    },

    trackMinibuzzImpression() {
      /*
        Currently not tracked but defined as a no-op function to keep compatibility with BFP
        formats that call this function.
       */
    },

    trackBfpNewsletterSignup(path, { data }) {
      trackClientContentAction(buzz, {
        action_type: 'signup',
        action_value: data.newsletter,
        item_name: 'email',
        item_type: 'submission',
        position_in_subunit: 0,
        position_in_unit: 0,
        subunit_name: 'newsletter_signup',
        subunit_type: 'component',
        unit_name: buzz.id,
        unit_type: 'buzz_bottom',
      });
    },
  };

  // add functions to root tracker, to be called as tracker.functionName
  Object.keys(bfpEvents).forEach(k => (tracker[k] = bfpEvents[k]));
}
