import { useMemo } from 'react';
import { container } from './social-widgets.module.scss';
import TwitterTimeline from './components/TwitterTimeline';
import FacebookPagePlugin from './components/FacebookPagePlugin';

function SocialWidgets({ twitterPageUrl, facebookPageUrl, displayName = '' }) {
  const twitterDataSource = useMemo(
    () => ({ sourceType: 'url', url: twitterPageUrl }),
    [twitterPageUrl]
  );
  const twitterOptions = useMemo(() => ({ height: 600 }), []);

  if (!twitterPageUrl && !facebookPageUrl) {
    return null;
  }

  return (
    <div className={container}>
      {facebookPageUrl && <FacebookPagePlugin url={facebookPageUrl} />}
      {twitterPageUrl && (
        <TwitterTimeline
          dataSource={twitterDataSource}
          options={twitterOptions}
          displayName={displayName}
        />
      )}
    </div>
  );
}

export default SocialWidgets;
