import { useEffect, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import stickyRegistry from '@buzzfeed/bf-utils/lib/sticky-registry';
import { useComponentSize, useIntersection } from '@buzzfeed/react-components';
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import SidebarAdUnit from '../../../Ads/components/SidebarAd';
import createSidebarAdManager from '../../../Ads/managers/SidebarAdManager';
import SidebarDensity from '../../components/SidebarDensity';
import {
  container,
  transitioning,
  transitionDuration,
} from './sidebar-paginated.module.scss';

function useSidebarPagination(buzz) {
  const [page, _setPage] = useState(0);
  const [isPageChanging, setIsPageChanging] = useState(false);
  const setPage = useCallback(
    newPage => {
      if (newPage === 0) {
        _setPage(0);
        return;
      }

      setIsPageChanging(true);
      setTimeout(() => {
        _setPage(newPage);
        setIsPageChanging(false);
      }, parseInt(transitionDuration, 10));
    },
    // We need the sidebar page to be reset to 0 when buzz.id changes (i.e. on navigation),
    // but ESLint thinks `buzz.id` is an unnecessary dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buzz.id]
  );

  return [page, setPage, isPageChanging];
}

function SidebarPaginated({ buzz, containerRef, pixiedust }) {
  const adsContext = useContext(AdsContext);
  const [page, setPage, isPageChanging] = useSidebarPagination(buzz);
  const { height: maxHeight } = useComponentSize(containerRef);
  const { isIntersecting, setObservable } = useIntersection({
    threshold: 0.5,
    pageId: buzz.id,
  });

  useEffect(() => {
    setPage(0);
  }, [setPage]);

  const [adManager, setAdManager] = useState(null);
  useEffect(() => {
    if (adsContext.status !== 'loaded') {
      return () => {};
    }

    const manager = createSidebarAdManager(buzz);
    manager.init().then(() => {
      setAdManager(manager);
    });
    return () => {
      setAdManager(null);
      manager.destroy();
    };
  }, [adsContext]);

  const [topAdjustment, setTopAdjustment] = useState(0);
  const stickyHandler = useCallback(() => {
    const top = stickyRegistry.getAvailableTop(containerRef.current);
    if (topAdjustment !== top) {
      setTopAdjustment(top);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buzz.id, containerRef]);
  // check with the global sticky registry to see whether the top needs to be adjusted
  // due to there being other sticky elements above (e.g. header, Spotlight ad)
  useEffect(() => {
    stickyHandler();
    stickyRegistry.subscribe(stickyHandler);
    return () => stickyRegistry.unsubscribe(stickyHandler);
  }, [buzz.id, stickyHandler]);

  return (
    <div
      ref={setObservable}
      className={`${container} ${isPageChanging ? transitioning : ''}`}
      style={{ top: `${topAdjustment}px` }}
    >
      <SidebarDensity
        sidebarPage={page}
        onNextPageReady={() => setPage(p => p + 1)}
        maxHeight={maxHeight}
        isIntersecting={isIntersecting}
        buzzId={buzz.id}
        adManager={adManager}
      >
        <SidebarAdUnit adManager={adManager} pixiedust={pixiedust} />
        <SidebarAdUnit adManager={adManager} pixiedust={pixiedust} />
      </SidebarDensity>
    </div>
  );
}

SidebarPaginated.propTypes = {
  buzz: PropTypes.object,
  containerRef: PropTypes.shape({ current: PropTypes.object }),
};

export default SidebarPaginated;
