export { default as ads } from '@buzzfeed/adlib/dist/module/core/ads';

import { AdUnit } from '@buzzfeed/adlib/dist/module/core';

import AdAffiliatePixel from '@buzzfeed/adlib/dist/module/components/affiliate-pixel';
import AdAwareness from '@buzzfeed/adlib/dist/module/components/awareness';
import AdBigstory from '@buzzfeed/adlib/dist/module/components/bigstory';
import AdBpageStory from '@buzzfeed/adlib/dist/module/components/bpage-story';
import { AdExBase, AdEx } from '@buzzfeed/adlib/dist/module/components/ex';
import AdExGrid from '@buzzfeed/adlib/dist/module/components/ex-grid';
import AdGridStory from '@buzzfeed/adlib/dist/module/components/grid-story';
import AdPromoInline from '@buzzfeed/adlib/dist/module/components/promo-inline';
import AdSubbuzz from '@buzzfeed/adlib/dist/module/components/subbuzz';
import AdToolbar from '@buzzfeed/adlib/dist/module/components/toolbar';

import AdAffiliatePixelTemplate from '@buzzfeed/adlib/dist/module/components/affiliate-pixel/index.svelte';
import AdAwarenessTemplate from '@buzzfeed/adlib/dist/module/components/awareness/index.svelte';
import AdBigstoryTemplate from '@buzzfeed/adlib/dist/module/components/bigstory/index.svelte';
import AdBpageStoryTemplate from '@buzzfeed/adlib/dist/module/components/bpage-story/index.svelte';
import AdExTemplate from '@buzzfeed/adlib/dist/module/components/ex/index.svelte';
import AdExGridTemplate from '@buzzfeed/adlib/dist/module/components/ex-grid/index.svelte';
import AdGridStoryTemplate from '@buzzfeed/adlib/dist/module/components/grid-story/index.svelte';
import AdPromoInlineTemplate from '@buzzfeed/adlib/dist/module/components/promo-inline/index.svelte';
import AdSubbuzzTemplate from '@buzzfeed/adlib/dist/module/components/subbuzz/index.svelte';
import AdToolbarTemplate from '@buzzfeed/adlib/dist/module/components/toolbar/index.svelte';

AdUnit.prototype.getEventId = function() {
  return `${this.config.adPos}-${this.instanceId}`;
};

export const components = {
  AdAffiliatePixel,
  AdAwareness,
  AdBigstory,
  AdBpageStory,
  AdExBase,
  AdEx,
  AdExGrid,
  AdGridStory,
  AdPromoInline,
  AdSubbuzz,
  AdToolbar
};

export const templates = {
  AdAffiliatePixel: AdAffiliatePixelTemplate,
  AdAwareness: AdAwarenessTemplate,
  AdBigstory: AdBigstoryTemplate,
  AdBpageStory: AdBpageStoryTemplate,
  AdEx: AdExTemplate,
  AdExGrid: AdExGridTemplate,
  AdGridStory: AdGridStoryTemplate,
  AdPromoInline: AdPromoInlineTemplate,
  AdSubbuzz: AdSubbuzzTemplate,
  AdToolbar: AdToolbarTemplate,
};
