import { useContext, useEffect, useRef } from 'react';
import { useIntersection, withErrorBoundary } from '@buzzfeed/react-components';
import { trackClientScrollDepth } from '../../hooks/analytics/client-event-tracking';
import composeRefs from '@seznam/compose-react-refs';
import StickyContext from '../../contexts/sticky';
import { scrollY } from '../../utils/scroll';
import { captureException } from '@sentry/nextjs';

function ContentBottom({ buzz }) {
  const elementRef = useRef();
  const { isIntersecting, setObservable } = useIntersection({
    once: true,
    threshold: 1,
  });
  const { observeArticleBottom } = useContext(StickyContext);

  useEffect(() => {
    if (elementRef.current && isIntersecting) {
      const { top } = elementRef.current.getBoundingClientRect();
      trackClientScrollDepth(buzz, {
        pixel_depth: parseInt(scrollY() + top, 10),
        marker: 'content_bottom',
      });
    }
  }, [buzz, isIntersecting]);

  useEffect(() => {
    observeArticleBottom(elementRef.current);
  }, [observeArticleBottom]);

  return (
    <div
      ref={composeRefs(elementRef, setObservable)}
      style={{ width: '100%', height: '1px', clear: 'both' }}
    />
  );
}

export default withErrorBoundary(ContentBottom, {
  onError: captureException,
});
