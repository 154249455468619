import { useCallback, useContext } from 'react';
import { trackFacebookShareClick, trackFacebookLead } from './facebook/client';
import { trackClientContentAction } from './client-event-tracking';
import ConsentContext from '../../contexts/consent';

/**
 * Creates generator function for tracking events
 *
 * Import at the top of the component/file as:
 * const trackingFnGenerator = useShareTracking(buzz)
 *
 * Then, get platform-specific function as:
 * const platformFn = trackingFnGenerator({ platform });
 *
 * Bind the tracking fn to the DOM via react props or element.addEventLister
 *
 * @returns {Function}
 */
export function useShareTracking(buzz, subbuzzId) {
  const { tracking: { consentValue, isConsentReady } } = useContext(ConsentContext);

  // keys correspond to allowed types in `position` property of <ShareBar> component
  const shareUnitLayers = {
    subbuzz: {
      // override default
      item_name: subbuzzId,
      item_type: 'subbuzz',
      subunit_name: '',
      subunit_type: '',
      unit_type: 'buzz_body',
    },
    bottom_share_bar: {
      subunit_name: 'share_buttons',
      subunit_type: 'component',
      unit_type: 'buzz_bottom',
    },
    top_share_bar: {
      subunit_name: '',
      subunit_type: '',
      unit_type: 'buzz_head',
    },
    quiz_results: {
      subunit_name: '',
      subunit_type: '',
      unit_type: 'buzz_body',
    },
  };

  return useCallback(
    ({ platform, position, ...eventInfo }) => () => {
      if (!buzz || !buzz.id) {
        return;
      }

      const unitData = shareUnitLayers[position.replace(/-/g, '_')] || {};

      trackClientContentAction(buzz, {
        action_type: 'share',
        action_value: platform,
        item_name: platform,
        item_type: 'button',
        unit_name: buzz.id,
        ...unitData,
        ...eventInfo,
      });

      // consent required for this tracking
      if (isConsentReady && consentValue) {
        trackFacebookShareClick(buzz.destination_name, buzz, {
          platform,
          section: buzz.classification.section,
        });

        // also track leads for ad posts only
        if (buzz.isAd) {
          trackFacebookLead(buzz.destination_name, buzz, {
            content_category: buzz.bylines[0].username,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buzz.id, buzz, consentValue, isConsentReady]
  );
}
