import PropTypes from 'prop-types';
import { ShareButton, useIntersection } from '@buzzfeed/react-components';

import styles from '../share-bar.module.scss';

export function PinterestOverlayShareButton({ platformOptions, url }) {
  const { isIntersecting, setObservable } = useIntersection({
    rootMargin: '0px 0px -50% 0px',
  });

  return (
    <li ref={setObservable}>
      <ShareButton
        className={`${styles.shareButton} ${styles.pinterestOverlay} ${
          isIntersecting ? styles.show : ''
        }`}
        label={platformOptions.savePinText || 'Save'}
        platform="pinterest"
        platformOptions={platformOptions}
        showLabel={true}
        url={url}
        variant="small"
      />
    </li>
  );
}

PinterestOverlayShareButton.propTypes = {
  platformOptions: PropTypes.object,
  url: PropTypes.string,
};

export default PinterestOverlayShareButton;
