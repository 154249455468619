import dynamic from 'next/dynamic';

// Higher Order Function which allows us to centrally toggle
// dynamic components based on a flag.
// This is used to allow us to measure the performance impact of
// these components which are loaded after the initial render.
const dynamicImport = (fn, { ssr = true, suspense = false, loading = () => null } = {}) => {
  // https://regex101.com/r/dnEdq0/1
  if (
    typeof window !== 'undefined' &&
    window.location.search.match(/(\?|&)layer=content/)
  ) {
    // eslint-disable-next-line react/display-name
    return () => <div />;
  }
  // Some special handling of the options object. It needs to be an object literal. More info in
  // this issue: https://github.com/vercel/next.js/issues/32589#issuecomment-1016311047
  return dynamic(fn, {
    loading,
    ssr,
    suspense,
  });
};

export default dynamicImport;
