import React, { useContext } from 'react';
import BuzzContext from '../../../../contexts/buzz';
import LazyLoad from '../../../LazyLoad';
import { isEligible } from './utils';
import dynamicImport from '../../../../utils/dynamic-import';

const BottomRecircList = dynamicImport(
  () => import(/* webpackChunkName: "recirculation-bottom" */ './index'),
  { ssr: false }
);

export default function BottomRecircListLazy(props) {
  const buzz = useContext(BuzzContext);

  if (!isEligible(buzz)) {
    return null;
  }

  // do not lazy-load on partner posts since the `story-bpage` ad call needs to be made on page load
  return (
    <LazyLoad lazy={!buzz.isAd}>
      <BottomRecircList {...props} />
    </LazyLoad>
  );
}
