import { useMemo } from 'react';
import { useShareTracking } from '../useShareTracking';
import addBfpEvents from './events/bfp';
import addQuizEvents from './events/quiz';
import addSubbuzzVideoEvents from './events/subbuzzVideo';

/**
 * Functions to support non-react environments where we need to provide a tracking function in context.
 *
 * The methods defined below will be passed to SRA via the `tracking` key in the T3 config in the useT3 hook
 * and injected into AdLib via the `tracking` key in the Ads context in the Ads component.
 *
 * NOTE: If you are overriding the bfa helpers in `bfp-libs`, make sure that
 * the method names match up:
 * https://github.com/buzzfeed/mono/blob/master/packages/frontend/bfp-libs/src/bfa.js
 */

export default function useLegacyTracking(buzz) {
  const createShareTrackingFn = useShareTracking(buzz);

  return useMemo(() => {
    const tracking = {
      // key-value pairs of legacy BFA route (e.g. "track/click/link") to tracking function to invoke
      // the additional tracking function event modules will append keys to this map as needed
      trackingPathsMap: {},

      track(path, eventInfo) {
        if (tracking.trackingPathsMap[path]) {
          tracking.trackingPathsMap[path](eventInfo);
        }

        // @todo - add sentry event call here for paths that fall through so we can track BFA events that are not completing
      },
    };

    // mutate tracking object and add additional tracking functions from event modules
    addQuizEvents(tracking, {
      buzz,
      createShareTrackingFn,
    });

    addBfpEvents(tracking, { buzz });

    addSubbuzzVideoEvents(tracking, { buzz });

    return tracking;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buzz.id, createShareTrackingFn]);
}
