import { useEffect, useRef, useState } from 'react';
import { TwitterIcon } from '@buzzfeed/react-components';
import PropTypes from 'prop-types';
import { loadScript } from '@buzzfeed/bf-utils/lib/load-script';
import styles from './twitter-timeline.module.scss';

// Component that creates Twitter's timeline widget using Twitter JS SDK
// See https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/scripting-factory-functions
// for available data sources and configuration options
function TwitterTimeline({ dataSource, options = {}, displayName = '' }) {
  const element = useRef();
  const widgetContainer = useRef();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!element.current) {
      return;
    }

    (async function createTimeline() {
      if (!window.twttr) {
        await loadScript('https://platform.twitter.com/widgets.js');
      }

      // It seems like an element can only be processed by the SDK once
      // So we'll keep recycling a new child element on prop changes
      // This is a bit weird for React world, but is a limitation of a 3rd party lib
      if (widgetContainer.current) {
        element.current.removeChild(widgetContainer.current);
      }
      widgetContainer.current = document.createElement('div');
      element.current.appendChild(widgetContainer.current);

      try {
        window.twttr.widgets.createTimeline(
          dataSource,
          widgetContainer.current,
          options
        );
      } catch {
        setError(true);
      }
    })();
  }, [dataSource, options]);

  return (
    <>
      {error && (
        <a href={dataSource.url} className={styles.twitterTimelineFallback}>
          <TwitterIcon width={16} height={16} />
          Tweets by {displayName}
        </a>
      )}
      <div ref={element} style={{ height: options?.height }} />
    </>
  );
}

TwitterTimeline.propTypes = {
  dataSource: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export default TwitterTimeline;
