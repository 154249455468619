import { useContext, useEffect, useRef } from 'react';
import {
  isOn,
  getExperimentVariant,
} from '@buzzfeed/react-components/lib/utils/abeagle';
import ABeagleContext from '../../contexts/abeagle';
import BuzzContext from '../../contexts/buzz';

function getControlledPromise() {
  let resolve;
  const promise = new Promise(r => (resolve = r));
  return [promise, resolve];
}

/**
 * Async version of the ABeagle client functions
 * so that the consuming component can use them right away
 * instead of having to check whether experiments are loaded on every render
 * (since the functions provided by `react-components` are synchronous
 * and simply return null when experiments are not loaded yet)
 * @todo Move to `react-components`?
 */
export default function useABeagleAsync() {
  const { experiments } = useContext(ABeagleContext);
  const buzz = useContext(BuzzContext);
  const prevBuzzId = useRef();

  const experimentsRef = useRef({});
  if (!prevBuzzId.current || buzz.id !== prevBuzzId.current) {
    [
      experimentsRef.current.loaded,
      experimentsRef.current.resolve,
    ] = getControlledPromise();
  }

  prevBuzzId.current = buzz.id;

  const abeagle = useRef({
    experiments: experimentsRef.current.loaded,
    isOn: function() {
      return experimentsRef.current.loaded.then(abtests =>
        isOn(abtests, ...arguments)
      );
    },
    getExperimentVariant: function() {
      return experimentsRef.current.loaded.then(abtests =>
        getExperimentVariant(abtests, ...arguments)
      );
    },
  });

  useEffect(() => {
    if (experiments && experiments.loaded && !experiments.stale) {
      experimentsRef.current.resolve(experiments);
    }
  }, [experiments]);

  return abeagle.current;
}
