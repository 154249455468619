import React from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from '@buzzfeed/react-components';
import { ResizeProvider } from '../../contexts/resize';

export default function ResizeManager({ children }) {
  const value = {
    isMobile: useBreakpoint('(max-width:500px)'),
  };

  return <ResizeProvider value={value}>{children}</ResizeProvider>;
}

ResizeManager.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
