import useLazyLoad from '../../hooks/useLazyLoad';

/**
 * Component that renders children lazily
 * @param {Boolean} [props.lazy=true] - whether rendering should be actually delayed
 * @param {Number} [props.preloadDistance=2] - see docs for `useLazyLoad`
 * @param {String} [props.rootMargin] - see docs for `useLazyLoad`
 */
export default function LazyLoad({ children, lazy = true, ...options }) {
  const { canLoad, setObservable } = useLazyLoad(options);

  return (
    <div ref={lazy ? setObservable : null}>
      {!lazy || canLoad ? children : null}
    </div>
  );
}
