import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { config as adsConfig } from '@buzzfeed/adlib/dist/module/bindings/react';
import Ad from '@buzzfeed/adlib/dist/module/bindings/react/components/Ad';
import { isMobile } from '@buzzfeed/bf-utils/lib/device';
import { isServer } from '../../utils/isServer';
import * as adUnitUtils from '../../utils/ads/ad-unit';
import BuzzContext from '../../contexts/buzz';
import StickyContext from '../../contexts/sticky';
import { withErrorBoundary } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import { isNews } from '../../utils/isNews';
import ABeagleContext from '../../contexts/abeagle';
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';

function AdUnit({
  type,
  config,
  mode,
  onAdViewed,
  onAdRender,
  buzzFormat = 'standard',
  className = '',
  pixiedust,
}) {
  const buzz = useContext(BuzzContext);
  const stickyContext = useContext(StickyContext);
  const [adStatus, setAdStatus] = useState('loading');
  const defaultRenderLookahead = !isServer() && isMobile() ? 'x0.5' : 'x0.25';
  const isBuzzNews = isNews(buzz);
  const { getFeatureFlagValue } = useContext(ABeagleContext);
  const adsContext = useContext(AdsContext);
  const userCountry = adsContext?.env?.userCountry;
  const isAUNZ = userCountry === 'au' || userCountry === 'nz';

  let destroyAdsOnScroll = getFeatureFlagValue(
    'ads_destroy_ads_on_scroll_mobile'
  ) || false;

  const cfg = useMemo(() => {
    const clonedConfig = { ...(config || adsConfig[type]) };

    if (isBuzzNews) {
      clonedConfig.zone1 = 'bfnews';
    }

    if (!('renderLookahead' in clonedConfig)) {
      clonedConfig.renderLookahead = defaultRenderLookahead;
    }
    return clonedConfig;
  }, [config, type, defaultRenderLookahead, isBuzzNews]);

  const handleAdRender = useCallback(
    ad => {
      if (ad.type) {
        // @todo Fix force-collapse creatives being treated as loaded
        setAdStatus(ad.type === 'empty' ? 'unfilled' : 'loaded');
      }
      if (typeof onAdRender === 'function') {
        onAdRender(ad);
      }
    },
    [cfg, onAdRender, buzz.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setAdStatus('loading');
  }, [cfg, buzz.id]);

  return (
    <Ad
      adUnitUtils={adUnitUtils}
      config={cfg}
      pageId={buzz.id}
      mode={mode}
      onAdViewed={onAdViewed}
      onAdRender={handleAdRender}
      stickyManager={stickyContext}
      isWidePage={buzzFormat === 'wide'}
      className={`${className} Ad--${
        buzz.isAd ? 'partner' : 'edit'
      } Ad--${adStatus}`}
      pixiedust={pixiedust}
      destroyAdsOnScroll={isAUNZ ? false : destroyAdsOnScroll}
    />
  );
}

AdUnit.propTypes = {
  type: PropTypes.string,
  config: PropTypes.object,
  mode: PropTypes.oneOf(['active', 'preload']),
  onAdViewed: PropTypes.func,
  onAdRender: PropTypes.func,
  buzzFormat: PropTypes.string,
  buzzLanguage: PropTypes.string,
  className: PropTypes.string,
  pixiedust: PropTypes.object,
};

export default withErrorBoundary(AdUnit, {
  onError: captureException,
});
