import { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DestinationContext from '../../../../contexts/destination';
import { loadScript } from '@buzzfeed/bf-utils/lib/load-script';

// Component that creates Faceboook's Page Plugin using Facebook JS SDK
// See https://developers.facebook.com/docs/plugins/page-plugin
function FacebookPagePlugin({ url }) {
  const element = useRef();
  const { facebook_app_id } = useContext(DestinationContext);

  useEffect(() => {
    if (!element.current) {
      return;
    }

    if (typeof window.FB === 'undefined') {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: facebook_app_id,
          xfbml: false,
          version: 'v2.9',
        });
        window.FB.XFBML.parse(element.current);
      };
      loadScript('https://connect.facebook.net/en_US/sdk.js');
    } else {
      window.FB.XFBML.parse(element.current);
    }
  }, [url, facebook_app_id]);

  return (
    <div ref={element}>
      <div
        className="fb-page"
        data-href={url}
        data-show-facepile={true}
        data-tabs="timeline"
        data-width="500"
        data-adapt-container-width="true"
        style={{ width: '100%' }}
      />
    </div>
  );
}

FacebookPagePlugin.propTypes = {
  url: PropTypes.string.isRequired,
};

export default FacebookPagePlugin;
