import fetch from 'isomorphic-unfetch';

export const fetchAPI = async (uri, { req }) => {
  const baseUrl = req ? `http://localhost:${process.env.PORT}` : '';
  const fetchOpts = req ? { headers: req.headers } : {};

  try {
    const response = await fetch(`${baseUrl}${uri}`, fetchOpts);
    if (!response.ok) {
      let error = await response.text();
      try {
        error = JSON.parse(error).message;
      } catch {
        // silently catch parse errors here and use text below
      }

      return { error: { error, statusCode: response.status } };
    }

    return response.json();
  } catch (e) {
    return {
      error: { error: e.message || 'Error fetching API data', statusCode: 500 },
    };
  }
};
