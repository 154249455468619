import { useBreakpoint, withErrorBoundary } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import { useContext, useRef } from 'react';
import BuzzContext from '../../contexts/buzz';
import DestinationContext from '../../contexts/destination';
import { useTranslation } from '../../i18n';
import dynamicImport from '../../utils/dynamic-import';
import styles from './sidebar.module.scss';
import SidebarBranded from './variants/SidebarBranded';
import SidebarPaginated from './variants/SidebarPaginated';

const SidebarWalmartBundle = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "walmart-bundle-sidebar" */ './variants/SidebarWalmartBundle'
    ),
  { ssr: false }
);

function Sidebar({ pixiedust }) {
  const buzz = useContext(BuzzContext);
  const { destination } = useContext(DestinationContext);
  const isWideScreen = useBreakpoint('(min-width: 64rem)');
  const sidebarElement = useRef(null);
  const { t } = useTranslation('common');

  if (typeof window !== 'undefined' && !isWideScreen) {
    return null;
  }

  const sidebarProps = {
    buzz,
    containerRef: sidebarElement,
    pixiedust,
  };

  return (
    <section
      className={`${styles.sidebar} ${styles[destination]} ${
        buzz.isAd ? styles.sidebarBranded : ''
      }`}
      aria-label={t('promoted_content')}
      ref={sidebarElement}
    >
      {
        buzz.walmartBundle
          ? <SidebarWalmartBundle {...sidebarProps} buzz={buzz} /> 
          : buzz.isAd 
            ? <SidebarBranded {...sidebarProps} /> 
            : <SidebarPaginated {...sidebarProps} />
      }
    </section>
  );
}

export default withErrorBoundary(Sidebar, {
  onError: captureException,
});
