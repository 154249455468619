import { CLUSTER } from '../../constants';
import { useEffect, useState } from 'react';
import { getUserId } from '@buzzfeed/bf-utils/lib/userid';

/**
 * State and effect hook: an anonymous identifier of the current user.
 * The identifier is resolved asynchronously, which means the initial state will be set to null.
 * When a component has a hard dependency on this identifier, it should skip the first render.
 * @example
 *
 *  function Component() {
 *    const userId = useUserId()
 *
 *    if (!userId) {
 *      return
 *    }
 *
 *    return <div>This is the user id: {userId}</div>
 *  }
 *
 * @returns {number?} anonymous identifier of the current user.
 */
export function useUserId() {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    (async () => {
      setUserId(await getUserId({ env: CLUSTER, legacy: false }));
    })();
  }, []);

  return userId;
}
